import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/layout/LayoutInner";
import InnerHead from "../components/layout/InnerHead";
import Seo from "../components/layout/SEO";
import MainQtrLeft from "../components/layout/MainQtrLeft";
import ImageBlock from "../components/elements/ImageBlock";
import Testimonials from "../components/home/Testimonials";
import OurCases from "../components/home/OurCases";
import Pitch from "../components/home/Pitch";
import { MDXRenderer } from "gatsby-plugin-mdx";

const AttorneyTemplate = ({ data }) => {
  const profileImage = getImage(data.mdx.frontmatter.hero_image);
  const { site, allTestimonialsJson, allResultsJson } = data;

  const company = site.siteMetadata;

  // Affiliation and Education fallbacks
  const education = data.mdx.frontmatter.education || [];
  const affiliations = data.mdx.frontmatter.affiliations || [];

  // JSON Schema
  const schema = {
    "@context": "https://schema.org",
    "@type": "Person",
    "name": data.mdx.frontmatter.title,
    "jobTitle": data.mdx.frontmatter.role,
    "worksFor": {
      "@type": "Organization",
      "name": company.company,
      "url": company.siteUrl,
      "logo": `${company.siteUrl}/images/${company.logo}`,
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": company.phone,
        "contactType": "customer service"
      },
      "address": {
        "@type": "PostalAddress",
        "streetAddress": company.address,
        "addressLocality": company.city,
        "addressRegion": company.state,
        "postalCode": company.zipcode
      }
    },
    "email": data.mdx.frontmatter.email,
    "phone": data.mdx.frontmatter.phone,
    "image": `https://dreyerinjurylaw.com${profileImage.images.fallback.src}`,
    "url": `https://dreyerinjurylaw.com/${data.mdx.frontmatter.canon}`,
    "alumniOf": education.map(edu => ({
      "@type": "CollegeOrUniversity",
      "name": edu.institution,
      "degree": edu.degree,
      "sameAs": edu.sameAs || ""
    })),
    "affiliation": affiliations.map(aff => ({
      "@type": "Organization",
      "name": aff.name,
      "sameAs": aff.sameAs || ""
    })),
    "areaServed": [
      {
        "@type": "Place",
        "name": "Newburgh, NY"
      },
      {
        "@type": "Place",
        "name": "Goshen, NY"
      },
      {
        "@type": "Place",
        "name": "Middletown, NY"
      },
      {
        "@type": "Place",
        "name": "Poughkeepsie, NY"
      },
      {
        "@type": "Place",
        "name": "Beacon, NY"
      },
      {
        "@type": "Place",
        "name": "Kingston, NY"
      },
      {
        "@type": "Place",
        "name": "Orange County, NY"
      },
      {
        "@type": "Place",
        "name": "Dutchess County, NY"
      },
      {
        "@type": "Place",
        "name": "Ulster County, NY"
      },
      {
        "@type": "Place",
        "name": "Hudson Valley, NY"
      }
    ]    
  };

  return (
    <Layout>
      <Seo 
        title={`About ${data.mdx.frontmatter.title}`}
        article="article"
        schema={schema}
      />
      <InnerHead title={`About ${data.mdx.frontmatter.title}`} />
      <main className="attorney-page">
        <MainQtrLeft>
          <div>
            <ImageBlock>
              <GatsbyImage image={profileImage} alt={data.mdx.frontmatter.title} />
            </ImageBlock>
            <div className="attorney-page--contact">
              <p>
                <span>Email:</span>
                {data.mdx.frontmatter.email}
              </p>
              <p>
                <span>Phone:</span>
                {data.mdx.frontmatter.phone}
              </p>
            </div>
          </div>
          <div className="attorney-page--bio">
            <MDXRenderer>{data.mdx.body}</MDXRenderer>
          </div>
          {/* Practice Areas */}
        </MainQtrLeft>

      </main>
      <Testimonials data={allTestimonialsJson} />
      <OurCases data={allResultsJson} />
      <Pitch />
    </Layout>
  )
};

export default AttorneyTemplate;
