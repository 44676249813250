import React from 'react';
import Seo from '../components/layout/SEO';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import { MDXRenderer } from 'gatsby-plugin-mdx';
// import FaClock from '../assets/faClock.svg';
import Layout from '../components/layout/LayoutInner';
import InnerHead from '../components/layout/InnerHead';
import MainQtrRight from '../components/layout/MainQtrRight';
import WidgetPractices from '../components/innerPage/WidgetPractices';

const NewsTemplate = ({ data }) => {

  const heroImage = getImage(data.mdx.frontmatter.hero_image);
  const thisDate = data.mdx.frontmatter.date;
  const thisCategory = data.mdx.frontmatter.category;

  const thisCaption = (date, category) => {
    return (
      <div className="story-page--header--caption">
        <span>
          {/* <FaClock /> */}
          {date}
        </span>
        &nbsp;&bull;&nbsp;
        <span className="story-page--item__category">
          {category}
        </span>
      </div>
    )
  }
  
  return (
    <Layout>
      <Seo
        title={data.mdx.frontmatter.title}
        canonical={data.mdx.frontmatter.canon}
        description={data.mdx.excerpt}
        article='article'
      />
      <InnerHead title={data.mdx.frontmatter.title} caption={thisCaption(thisDate, thisCategory)} />
      {/* <div className="story-page--header">
        <h1 className="story-page--header--title">
          {data.mdx.frontmatter.title}
        </h1>
        <div className="story-page--header--caption">
          <span>
            <FaClock />
            {data.mdx.frontmatter.date}
          </span>
          &nbsp;&bull;&nbsp;
          <span className="blog-page--item__category">
            {data.mdx.frontmatter.category}
          </span>
        </div>
      </div> */}
      <main className="story-page">
        <MainQtrRight>
          <div>
            <div className="story-page--hero">
              <BgImage className="story-page--hero-image" image={heroImage} alt={data.mdx.frontmatter.title} />
            </div>
            <div className="story-page--content">
              <MDXRenderer>
                {data.mdx.body}
              </MDXRenderer>
            </div>
          </div>
          <WidgetPractices />
        </MainQtrRight>
      </main>
    </Layout>
  )
}

export default NewsTemplate
