import { graphql } from "gatsby"
import React, { Fragment } from "react"

import AttorneyTemplate from '../../templates/attorney-template'
import NewsTemplate from '../../templates/news-template'
import PracticeTemplate from '../../templates/practice-template'

const MdxPage = ({
  data,
  data: {
    mdx: {
      frontmatter: { datum },
    },
  },
}) => {
  const templates = {
    attorneys: <AttorneyTemplate data={data} />,
    blog: <NewsTemplate data={data} />,
    practiceAreas: <PracticeTemplate data={data} />,
  }

  return (
    <Fragment>
      {templates[datum] ? templates[datum] : null}
    </Fragment>
  );
}

export const query = graphql`
  query MdxPageQuery($id: String) {
    site {
      siteMetadata {
        company
        siteUrl
        phone
        email
        address
        city
        state
        zipcode
        logo
      }
    }
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        datum
        dir
        date(formatString: "MMMM Do, YYYY")
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }
        role
        email
        phone
        category
        canon
        education {
          institution
          degree
          sameAs
        }
        affiliations {
          name
          sameAs
        }
      }
      body
      excerpt
      slug
    }
    allMdx(
      filter: { fileAbsolutePath: { glob: "**/src/data/practice-areas/*" } }
      sort: { fields: frontmatter___order, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          slug
        }
      }
    }  
    allResultsJson(limit: 3) {
      edges {
        node {
          award
          category
          id
          narrative
        }
      }
    }
    allTestimonialsJson(limit: 4) {
      edges {
        node {
          id
          author
          excerpt
        }
      }
    }
  }
`

export default MdxPage
