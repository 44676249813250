import React from 'react';

export default function MainQtrRight({ children }) {
  return (
    <div className="quarter-right">
      <div className="container">
        {children}
      </div>
    </div>
  );
}
