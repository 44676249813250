import { Link } from 'gatsby';
import React from 'react';
import Seo from '../components/layout/SEO';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../components/layout/LayoutInner';
import InnerHead from '../components/layout/InnerHead';
import MainQtrLeft from '../components/layout/MainQtrLeft';
import WidgetPractices from '../components/innerPage/WidgetPractices';
import Pitch from '../components/home/Pitch';
import Partners from '../components/home/Partners';
import Flowline from '../components/layout/Flowline';
import ContactBlog from '../components/home/ContactBlog';

const PracticeTemplate = ({ data }) => {
  const { site } = data;
  const company = site.siteMetadata;
  
  // JSON Schema
  const schema = {
    "@context": "https://schema.org",
    "@type": "LegalService",
    "name": data.mdx.frontmatter.title,
    "provider": {
      "@type": "Organization",
      "name": company.company,
      "url": company.siteUrl,
      "logo": `${company.siteUrl}/images/${company.logo}`,
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": company.phone,
        "contactType": "customer service"
      },
      "address": {
        "@type": "PostalAddress",
        "streetAddress": company.address,
        "addressLocality": company.city,
        "addressRegion": company.state,
        "postalCode": company.zipcode
      }
    },
    "url": `https://dreyerinjurylaw.com/${data.mdx.frontmatter.canon}`,
    "areaServed": [
      {
        "@type": "Place",
        "name": "Newburgh, NY"
      },
      {
        "@type": "Place",
        "name": "Goshen, NY"
      },
      {
        "@type": "Place",
        "name": "Middletown, NY"
      },
      {
        "@type": "Place",
        "name": "Poughkeepsie, NY"
      },
      {
        "@type": "Place",
        "name": "Beacon, NY"
      },
      {
        "@type": "Place",
        "name": "Kingston, NY"
      },
      {
        "@type": "Place",
        "name": "Orange County, NY"
      },
      {
        "@type": "Place",
        "name": "Dutchess County, NY"
      },
      {
        "@type": "Place",
        "name": "Ulster County, NY"
      },
      {
        "@type": "Place",
        "name": "Hudson Valley, NY"
      }
    ]    
  };


  const profileImage = getImage(data.mdx.frontmatter.hero_image);


  return (
    <Layout>
      <Seo
        title={data.mdx.frontmatter.title}
        canonical={data.mdx.frontmatter.canon}
        description={data.mdx.excerpt}
        article='article'
        schema={schema}
      />
      <InnerHead title={data.mdx.frontmatter.title} caption="Dreyer Law" />
      <main class="practice-page">
        <MainQtrLeft>
          <WidgetPractices />
          <div className="practice-page--content">
            <BgImage className="practice-page--hero-image" image={profileImage} alt={data.mdx.frontmatter.title} />
            <MDXRenderer>
              {data.mdx.body}
            </MDXRenderer>
            <p>
              Set up a&nbsp;
              <Link to="/contact-us">free consultation</Link>
              &nbsp;by calling&nbsp;
              <a href={`tel:${site.siteMetadata.phone}`}>
                {site.siteMetadata.phone}
              </a>
              . We accept Personal Injury cases on a contingency fee basis, which means you pay nothing unless we recover.
            </p>
            {/* add News */}
            <div className="practice-page--highlights">
              <h3>When Results Matter</h3>
              <p>
                At Dreyer Law, we understand how serious injury and accident cases can impact your life. We have represented thousands of clients facing difficult personal and legal challenges. In every case, we work diligently to seek the most favorable result possible for our clients, while striving to surpass our clients' expectations for personal service. We provide personal, hands-on representation and maintain constant contact with our clients, something we have heard time and again most other law firms do not necessarily do on a consistent basis. Additionally, we pride ourselves on being results-oriented.
              </p>
              <div className="practice-page--highlights--grid">
                <div className="columnHighlight">
                  <div className="columnHighlight--inner">
                    <div className="columnHighlight--wrapper">
                      <div className="columnHighlight--title">
                        <h3>Available</h3>
                      </div>
                      <Flowline />
                      <div className="columnHighlight--content">
                        <p>
                          We provide personal, hands-on representation and maintain constant contact with our clients.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="columnHighlight">
                  <div className="columnHighlight--inner">
                    <div className="columnHighlight--wrapper">
                      <div className="columnHighlight--title">
                        <h3>Accomplished</h3>
                      </div>
                      <Flowline />
                      <div className="columnHighlight--content">
                        <p>
                          With a collective 75+ years of experience to draw upon, we have recovered millions of dollars for injury victims.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="columnHighlight">
                  <div className="columnHighlight--inner">
                    <div className="columnHighlight--wrapper">
                      <div className="columnHighlight--title">
                        <h3>Aggressive</h3>
                      </div>
                      <Flowline />
                      <div className="columnHighlight--content">
                        <p>
                          We thoroughly investigate personal injury cases to provide clients with the aggressive representation they require.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MainQtrLeft>
      </main>
      <Partners />
      <ContactBlog />
      <Pitch />
    </Layout>
  )
}

export default PracticeTemplate
